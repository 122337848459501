body { background-color: #ffffff; }
body { color: var(--clr-1125); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 30px;

}
h2 {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$large-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0.125em;
font-size: 26px;

@media #{$medium-up} {
font-size: 34px;

}
@media #{$large-up} {
font-size: 50px;

}
}
.button {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 24px;

}
@media #{$large-up} {
font-size: 30px;

}
}
summary {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

}
nav.mainmenu > .menu-item > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

@media #{$large-up} {
font-size: 20px;

}
}
body {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-1126);}
a:hover {color: var(--clr-1126);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-1125);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-1125);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-1125);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-1127);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-1127);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-1127);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-1128);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-1128);
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-1128);
 }
.MES8 {
background-color: var(--clr-1128);
 }
/* Alternate:9 */
.MES9 {
background-color: var(--clr-1128);
 }
.MES9 {
background-color: var(--clr-1128);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-1129);
 }
/* Light:11 */
.MES11 {
background-color: var(--clr-1129);
 }
.MES11 {
background-color: var(--clr-1129);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-1130);
color: #ffffff;
 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-1130);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-1130);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Copyright panel:14 */
.MES14 {
font-size: 12.8px;
 }
.MES14 {
font-size: 12.8px;
 }
cite.MEC14{
font-size: 12.8px;
}
/* News Grid:16 */
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-1128);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-1129);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-1128);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-1129);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: var(--clr-1125);
text-transform: uppercase;
}
 &:hover > a.MEC17{color: var(--clr-1131);
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 15px 20px;}




.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: var(--clr-1132);
color: #ffffff;
 }
/* Home slider:19 */
.MES19 {
& .slider-arrow {color: var(--clr-1133);
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 16;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 11;}
background-color: 2;
background-clip: padding-box;
&:hover {background-color: 11;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: 11;}
}}
 }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: var(--clr-1129);}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: var(--clr-1125);
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 0;}

& .sub-menu{ .sub-menu {}}

 }
/* login buttons:21 */
.MES21 {
background-color: var(--clr-1129);
color: var(--clr-1125);
border-radius: 50px;
padding: 5px 15px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Copyright panel grey:22 */
.MES22 {
background-color: var(--clr-1129);
font-size: 12.8px;
 }
.MES22 {
background-color: var(--clr-1129);
font-size: 12.8px;
 }
cite.MEC22{
font-size: 12.8px;
}
/* carousel padding:23 */
.MES23 {
padding: 10px;

 }
.MES23 {
padding: 10px;

 }
/* Carousel grey pad:24 */
.MES24 {
background-color: var(--clr-1128);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES24 {
background-color: var(--clr-1128);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
a.MEC24 { color: var(--clr-1132);
 }
/* border line:25 */
.MES25 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-1128) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: #ffffff;
color: var(--clr-1125);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-1129) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: #ffffff;
color: var(--clr-1125);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-1129) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-1127);
 }
h1.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 28px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 20px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 12.8px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 27.2px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 50px; }; }
 }
a.MEC26 { color: var(--clr-1125);
&:hover { color: var(--clr-1127);}
 }
cite.MEC26{
color: var(--clr-1125);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* Home banner White text:27 */
.MES27 {
color: var(--clr-1128);
 }
.MES27 {
color: var(--clr-1128);
h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: var(--clr-1128);
 }
&:hover { h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: var(--clr-1129);} }
 }
a.MEC27 { color: var(--clr-1128);
&:hover { color: var(--clr-1133);}
 }
cite.MEC27{
color: var(--clr-1128);
}
/* Home Menu:28 */
nav.me-Menu.MES28 {
& .menu-item.MEC28, & .menu-item.MEC28 > div.MEC28{ & > a.MEC28{color: var(--clr-1128);
text-transform: uppercase;
}
 &:hover > a.MEC28{color: var(--clr-1133);
}
 }
&.horizontal > .menu-item.MEC28 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC28 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC28 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC28 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 15px 20px;}




.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Rollover effect:29 */
.MES29 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-1125);}
color: var(--clr-1128);
 }
.MES29 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-1125);}
color: var(--clr-1128);
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: var(--clr-1128);
 }
&:hover { h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: var(--clr-1128);} }
 }
cite.MEC29{
color: var(--clr-1128);
}
/* Popup Sub Window:30 */
.MES30 {
background-color: #ffffff;
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
border-width: 5px;
border-style: solid;
border-color: var(--clr-1128);
 }
.MES30 {
background-color: #ffffff;
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
border-width: 5px;
border-style: solid;
border-color: var(--clr-1128);
 }
/* Alternate:31 */
.MES31 {
background-color: var(--clr-1134);
 }
.MES31 {
background-color: var(--clr-1134);
 }
/* Header panel:32 */
.MES32 {
 }
.MES32 {
 }
a.MEC32 { color: var(--clr-1125);
&:hover { color: var(--clr-1131);}
 }
/* Plain text button:33 */
.MES33 {
color: var(--clr-1125);
&:hover { color: var(--clr-1131);}
 }
/* Plain button panel:34 */
.MES34 {
color: var(--clr-1125);
font-size: 12.8px;
 }
.MES34 {
color: var(--clr-1125);
font-size: 12.8px;
 }
a.MEC34 { color: var(--clr-1132);
&:hover { color: var(--clr-1131);}
 }
cite.MEC34{
color: var(--clr-1125);
font-size: 12.8px;
}
